<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.allocation_report') }}</h4>
        </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
            <b-row>
              <b-col lg="6" sm="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="12"
                    label-for="report_type"
                    >
                    <template v-slot:label>
                    {{ $t('fertilizerReport.report_type') }} <span class="text-danger">*</span>
                    </template>
                   <b-form-radio-group
                      v-model="search.report_type"
                      :options="options"
                      class="mb-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                    ></b-form-radio-group>
                  </b-form-group>
              </b-col>
              <b-col lg="6" sm="12"></b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="L/C NO" vid="lc_no" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="lc_no"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('procurement.lc_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                        id="lc_no"
                        v-model="search.lc_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :placeholder="$t('procurement.lc_no')"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col v-if="search.report_type === 1" lg="6" sm="12">
                <ValidationProvider name="Port" vid="port_id">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="port_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('movement.port') }}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.port_id"
                      :options="portInfoSetupList"
                      id="port_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col v-if="search.report_type === 2" lg="6" sm="12">
                <ValidationProvider name="C&F Agent" vid="cnf_agent_id">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="cnf_agent_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('movement.c_and_f_agent') }}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.cnf_agent_id"
                      :options="cnfAgentList"
                      id="cnf_agent_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6">
                  <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
      <b-row >
        <b-col md="12" v-if="showData">
          <b-overlay :show='loading'>
            <iq-card v-if='datas.length'>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('fertilizerReport.allocation_report') }}</h4>
                      </template>
                      <template v-slot:headerAction>
                        <b-button class="btn_add_new mr-2" @click="pdfExport">
                          <i class="far fa-file-pdf"></i>{{ $t('globalTrans.export_pdf') }}
                        </b-button>
                        <export-excel
                          class="btn btn_add_new"
                          :data="dataCustomize"
                          :fields= "json_fields"
                          :title="$t('fertilizerReport.allocation_report')"
                          :name="$t('fertilizerReport.allocation_report') + '.xls'">
                          <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                        </export-excel>
                      </template>
                      <template v-slot:body>
                        <b-overlay>
                          <b-row mt-5>
                            <b-col md="12" class="table-responsive">
                              <div style="border: 2px solid;margin:10px;">
                                <b-row>
                                  <b-col>
                                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                    <slot v-if="search.report_type == 1">
                                      {{ $t('fertilizerReport.allocation_report_port_wise') }}
                                    </slot>
                                    <slot v-else>
                                      {{ $t('fertilizerReport.allocation_report_cnfg_agent_wise') }}
                                    </slot>
                                    </list-report-head>
                                    <div class="text-center">
                                      <table style="width:100%;color:black;">
                                        <tr>
                                          <td align="right" style="width:40%">{{ $t('procurement.lc_no') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:45%">{{ $n(search.lc_no, { useGrouping: false }) }}</td>
                                        </tr>
                                        <tr v-if="search.report_type === 1 && search.port_id" >
                                          <td align="right" style="width:40%">{{ $t('movement.port') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:45%">{{ getPortName(search.port_id) }}</td>
                                        </tr>
                                        <tr v-if="search.report_type === 2 && search.cnf_agent_id" >
                                          <td align="right" style="width:40%">{{ $t('movement.c_and_f_agent') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:45%">{{ getAgentName(search.cnf_agent_id) }}</td>
                                        </tr>
                                      </table>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <div class="col text-right">
                                    <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                                  </div>
                                </b-row>
                                <b-table-simple class="tg mt-3" hover bordered small caption-top>
                                  <b-thead>
                                    <b-tr>
                                      <b-td class="tg-0lax">{{$t('globalTrans.sl_no')}}</b-td>
                                      <b-td class="tg-0lax" v-if="report_type == 1">{{$t('movement.port')}} </b-td>
                                      <b-td class="tg-0lax" v-if="report_type == 2">{{$t('movement.c_and_f_agent')}} </b-td>
                                      <b-td class="tg-0lax">{{$t('movement.fertilizer_name') }}</b-td>
                                      <b-td class="tg-0lax" style="text-align:right !important">{{$t('movement.allocated_amount') }}</b-td>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr  v-for="(report, index1) in datas" :key="index1">
                                      <b-td class="tg-0lax">{{$n(index1+1)}}</b-td>
                                      <b-td class="tg-0lax" v-if="report_type == 1">{{ ($i18n.locale === 'bn') ? report.port_name_bn : report.port_name }}</b-td>
                                      <b-td class="tg-0lax" v-if="report_type == 2">{{ ($i18n.locale === 'bn') ? report.company_agent_name_bn : report.company_agent_name }}</b-td>
                                      <b-td class="tg-0lax">{{ ($i18n.locale === 'bn') ? report.fertilizer_name_bn : report.fertilizer_name }}</b-td>
                                      <b-td class="tg-0lax" v-if="report_type == 1" style="text-align:right !important">{{ $n(report.allocated_amount) }}</b-td>
                                      <b-td class="tg-0lax" v-if="report_type == 2" style="text-align:right !important">{{ $n(report.quantity) }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                      <b-th class="tg-0lax text-center" colspan="3" style="text-align:right !important">{{ $t('fertilizerReport.GrandTotalMTon') }}</b-th>
                                      <b-th class="tg-0lax" style="text-align:right !important">
                                        {{ $n(calculateTotal(datas)) }}
                                      </b-th>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </div>
                            </b-col>
                          </b-row>
                        </b-overlay>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{text-align: center !important; vertical-align: middle !important;}
.tg th{text-align: center !important; vertical-align: middle !important;}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, allocationReport } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import flatpickr from 'flatpickr'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      showData: false,
      search: {
        lc_no: '',
        cnf_agent: '',
        port: '',
        port_id: 0,
        cnf_agent_id: 0,
        report_title: 0,
        report_type: 1
      },
      options: [
        { item: 1, name: (this.$i18n.locale === 'bn' ? 'পোর্ট' : 'Port') },
        { item: 2, name: (this.$i18n.locale === 'bn' ? 'সিএন্ডএফ এজেন্ট' : 'C&F Agent') }
      ],
      datas: [],
      report_type: 1,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    json_fields: function () {
      let excelColumn = []
      let excelColumnBn = []
      if (this.report_type === 1) {
        excelColumn = {
          Serial: 'serial',
          Port: 'name',
          'Fertilizer Name': 'fertilizer_name',
          Amount: 'amount'
        }
        excelColumnBn = {
          সিরিয়াল: 'serial',
          পোর্ট: 'name',
          'সারের নাম': 'fertilizer_name_bn',
          'বরাদ্দের পরিমাণ': 'amount'
        }
      } else {
        excelColumn = {
          Serial: 'serial',
          'C&F Agent': 'name',
          'Fertilizer Name': 'fertilizer_name',
          Amount: 'amount'
        }
        excelColumnBn = {
          সিরিয়াল: 'serial',
          'সিএন্ডএফ এজেন্ট': 'name',
          'সারের নাম': 'fertilizer_name_bn',
          'বরাদ্দের পরিমাণ': 'amount'
        }
      }
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomize () {
      const listData = this.datas
      var serial = 0
      let totalAmount = 0
      const listContractor = listData.map(item => {
        serial += 1
        let name = ''
        let amount = ''
        if (this.report_type === 1) {
          name = (this.$i18n.locale === 'bn') ? item.port_name_bn : item.port_name
          amount = item.allocated_amount
          totalAmount += amount
        } else {
          name = (this.$i18n.locale === 'bn') ? item.company_agent_name_bn : item.company_agent_name
          amount = item.quantity
          totalAmount += amount
        }
        return Object.assign({}, item, {
          serial: this.$n(serial),
          name: name,
          amount: amount
        })
      })
      const Footer = {
        serial: this.$i18n.locale === 'bn' ? 'গ্র্যান্ড টোটাল' : 'Grand Total',
        amount: totalAmount
      }
      listContractor.push(Footer)
      return listContractor
    },
    portInfoSetupList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    cnfAgentList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.cnfAgentList.filter(item => item.status === 1)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
    flatpickr('.toDate', {})
    core.index()
  },
  methods: {
    calculateTotal (report) {
      if (this.report_type === 1) {
        return report.map(r => r.allocated_amount).reduce((a, c) => {
          a = a ? parseInt(a) : 0
          c = c ? parseInt(c) : 0
          return a + c
        })
      } else {
        return report.map(r => r.quantity).reduce((a, c) => {
            a = a ? parseInt(a) : 0
            c = c ? parseInt(c) : 0
            return a + c
        })
      }
    },
    async register () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      if (this.search.port_id) {
        this.search.port = this.getPortName(this.search.port_id)
      }
      if (this.search.cnf_agent_id) {
        this.search.cnf_agent = this.getAgentName(this.search.cnf_agent_id)
      }
      if (this.search.report_type === 1) {
        this.search.report_title = (this.$i18n.locale === 'bn' ? 'বন্দর' : 'Port')
      }
      if (this.search.report_type === 2) {
        this.search.report_title = (this.$i18n.locale === 'bn' ? 'সিএন্ডএফ এজেন্ট' : 'C&F Agent')
      }
      this.report_type = this.search.report_type
      this.loading = true
        await RestApi.getData(seedFertilizerServiceBaseUrl, allocationReport, this.search).then(response => {
        if (response.success) {
          if (response.data) {
            this.datas = response.data
            this.showData = true
          }
        } else {
          this.datas = []
        }
      })
      this.loading = false
    },
    pdfExport () {
      const programNo = this.search.lc_no ?? ''
      let reportTypeTitle = ''
      let reportType = ''
      if (this.search.port_id || this.search.cnf_agent_id) {
        reportTypeTitle = (this.report_type === 1) ? this.$t('movement.port') : this.$t('movement.c_and_f_agent')
        reportType = (this.report_type === 1) ? this.getPortName(this.search.port_id) : this.getAgentName(this.search.cnf_agent_id)
      }
      let reportTitle = this.$t('fertilizerReport.allocation_report_port_wise')
      if (this.search.report_type === 2) {
        reportTitle = this.$t('fertilizerReport.allocation_report_cnfg_agent_wise')
      }
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.datas, this, programNo, reportTypeTitle + ' : ' + reportType, this.report_type, this.search)
    },
    getPortName (Id) {
      if (Id) {
        const port = this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.find(item => item.value === parseInt(Id))
        if (typeof port === 'undefined') {
          return ''
        } else {
          return port.text
        }
      }
    },
    getAgentName (Id) {
      const agent = this.$store.state.SeedsFertilizer.commonObj.cnfAgentList.find(item => item.value === parseInt(Id))
      if (typeof agent === 'undefined') {
        return ''
      } else {
        return agent.text
      }
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
