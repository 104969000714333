import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, programNo = '', typeName = '', reportType = '', search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('procurement.lc_no'), alignment: 'right', style: 'td' },
            { text: ':', alignment: 'center', style: 'td' },
            { text: vm.$n(search.lc_no, { useGrouping: false }), alignment: 'left', style: 'td' }
          ]
        ]
        if (search.report_type === 1 && search.port_id) {
          allRowsHead.push([
            { text: vm.$t('movement.port'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: search.port, style: 'td', alignment: 'left' }
          ])
        }
        if (search.report_type === 2 && search.cnf_agent_id) {
          allRowsHead.push([
            { text: vm.$t('movement.c_and_f_agent'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: search.cnf_agent, style: 'td', alignment: 'left' }
          ])
        }
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['45%', '5%', '45%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        let allRows = []
        if (reportType === 1) {
          allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.port'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.fertilizer_name'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.allocated_amount'), style: 'th', alignment: 'right' }
            ]
          ]
        } else {
          allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.c_and_f_agent'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.fertilizer_name'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.allocated_amount'), style: 'th', alignment: 'right' }
            ]
          ]
        }
        let totalAmt = 0
        data.map((report, index) => {
          if (reportType === 1) {
            allRows.push(
            [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? report.port_name_bn : report.port_name, style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? report.fertilizer_name_bn : report.fertilizer_name, style: 'td', alignment: 'center' },
              { text: vm.$n(report.allocated_amount), style: 'td', alignment: 'right' }
            ])
            totalAmt += parseInt(report.allocated_amount)
          } else {
            allRows.push(
              [
                { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
                { text: (i18n.locale === 'bn') ? report.company_agent_name_bn : report.company_agent_name, style: 'td', alignment: 'center' },
                { text: (i18n.locale === 'bn') ? report.fertilizer_name_bn : report.fertilizer_name, style: 'td', alignment: 'center' },
                { text: vm.$n(report.quantity), style: 'td', alignment: 'right' }
              ])
              totalAmt += parseInt(report.quantity)
          }
        })
        allRows.push(
          [
            { text: vm.$t('fertilizerReport.GrandTotalMTon'), style: 'th', colSpan: 3, alignment: 'right', bold: true },
            {},
            {},
            { text: vm.$n(totalAmt), style: 'th', alignment: 'right', bold: true }
          ]
        )
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['9%', '31%', '30%', '30%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 10]
          },
          fertilizer: {
            margin: [0, 7, 0, 7]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('allocation-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
